// $primary: rgb(137, 12, 209);
// $danger: #ff4136;

// $theme-colors: (
//   "primary": $primary
// );
@import url(https://fonts.googleapis.com/css?family=Hind+Vadodara:400,500&display=swap);
@import url(https://indestructibletype.com/fonts/Jost.css);

@import "../css/dark.css";
@import "/node_modules/bootstrap/scss/bootstrap.scss";
@import "input-group";
@import "_table";
@import "react-select";

@import "../css/theme.css";

.innerWrapper {
  align-items: stretch;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  &::before {
    background: #28304e;
    content: " ";
    height: 264px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.sidebar {
  background: transparent;
  border-right: 0;

  box-shadow: 0 0 2rem 0 rgb(0, 0, 0 / 5%);
  max-width: 250px;
  min-width: 250px;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out,
    margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
  z-index: 1;
}

.sidebar-brand,
.sidebar-brand:hover {
  background: #28304e;
  color: #fff;
  display: block;
  font-size: 1.175rem;
  font-weight: 500;
  padding: 1rem;
  text-decoration: none;
  width: 100%;
}

.sidebar-content,
.sidebar-nav {
  background: #fff;
  display: flex;
  flex-direction: column;
}

.sidebar-content {
  height: 100%;
  min-height: 100%;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out,
    margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
}

.sidebar-nav {
  flex: 1;
  justify-content: flex-start;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.rc-collapse-content {
  background-color: transparent !important;
}

.sidebarmenu-nav {
  padding-left: unset !important;
}

.form_cover {
  /* padding: 10px 0px;
  -- border: 1px solid #c1c1c1;
  background: #fff;
  border-radius: 5px;
  margin: 0;
  box-shadow: 0px 0px 6px #d6d6d6;
  margin-bottom: 20px; */

  padding: 10px 0px;
  border: 0.5px solid darkgray;
  background: #fff;
  border-radius: 5px;
  margin: 0;
  box-shadow: 0px 5px 10px darkgray;
  margin-bottom: 20px;
}

.customTabs .react-tabs__tab-panel {
  padding: 15px;
  background: #fff;
}
.customTabs .react-tabs__tab:focus:after {
  display: none;
}
.customTabs .react-tabs__tab {
  background: #e9ecef;
  // color: #772967;
  color: #28304e;
  border-radius: 4px 4px 0px 0px;
  margin-left: 2px;
  bottom: 0;
}
.customTabs .react-tabs__tab:first-child {
  margin-left: 0px;
}
.customTabs .react-tabs__tab-list {
  /* display: flex */
  margin: 0px;
  border: 0px;
}
.customTabs .react-tabs__tab {
  border: 0px !important;
  text-transform: capitalize;
  font-size: 16px;
  padding: 8px 15px 10px;
  /* -ms-flex: 1 1 50%;
  flex: 1 1 50%; */
}
.customTabs .react-tabs__tab:focus {
  outline: none;
  border: 0px;
  box-shadow: none;
}
.customTabs .react-tabs__tab-panel {
  padding: 15px;
  // -webkit-box-shadow: 1px 5px 15px -13px rgba(0, 0, 0, 0.65);
  // -moz-box-shadow: 1px 5px 15px -13px rgba(0, 0, 0, 0.65);
  // box-shadow: 1px 5px 15px -13px rgba(0, 0, 0, 0.65);
}
.customTabs .customInnerTabs .react-tabs__tab-panel {
  padding: 15px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(242, 242, 242, 1);
  -moz-box-shadow: 0px 0px 5px 1px rgba(242, 242, 242, 1);
  box-shadow: 0px 0px 5px 1px rgba(242, 242, 242, 1);
}
.customTabs .react-tabs__tab.react-tabs__tab--selected {
  background: #28304e; // #772967;
  border-color: #fff !important;
  color: white;
  border: 1px solid !important;
}
.customTabs .customInnerTabs .react-tabs__tab.react-tabs__tab--selected {
  background: #e9ecef;
  color: #772967;
}
.customTabs .customInnerTabs .react-tabs__tab {
  background: transparent;
}

.customTabs {
  margin-bottom: 10px;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.form-control {
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  color: #495057 !important;
  display: block;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.25rem 0.7rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  outline: none !important;
  &:focus {
    // outline:none !important;
    // outline-width: 2 !important;
    // box-shadow: none !important;
    // -moz-box-shadow: none!important;
    // -webkit-box-shadow: none !important;
    box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
    -moz-box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
    -webkit-box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
  }
  &:invalid {
    box-shadow: 0 0 0 0.1rem rgba(229, 2, 2, 0.25);
    -moz-box-shadow: 0 0 0 0.1rem rgba(229, 2, 2, 0.25);
    -webkit-box-shadow: 0 0 0 0.1rem rgba(229, 2, 2, 0.25);
  }
}

input {
  &:invalid {
    box-shadow: 0 0 0 0.1rem rgba(229, 2, 2, 0.25);
    -moz-box-shadow: 0 0 0 0.1rem rgba(229, 2, 2, 0.25);
    -webkit-box-shadow: 0 0 0 0.1rem rgba(229, 2, 2, 0.25);
  }
  & :focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
    -moz-box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
    -webkit-box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
  }
}

.error {
  border-width: 1px 1px 1px 10px;
  border-style: solid;
  border-color: rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80)
    rgb(236, 89, 144);
  border-image: initial;
  background: rgb(251, 236, 242);
}

.filepond--credits {
  display: none;
}

.agGrid-height-width {
  height: 400px;
  width: 100%;
}

.agGrid-button {
  height: 22px;
  padding: 1px 5px;
  font-size: 11px;
  border-radius: 0px;
}

.agGrid-button-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.overdue {
  background-color: #faa0a0 !important;
}
.cleared {
  background-color: #bff0bf !important;
}

.form-control-lite {
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: #fff;
  &:focus {
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: #fff !important;
  }
}
