/******************** InnerPage SideBar CSS *************************/
.themeWrapper {
  height: 100vh;
}
.themeWrapper .innerWrapper {
  position: relative;
}
.themeWrapper .innerWrapper > div:before {
  width: 100%;
  /* height: 180px; */
  position: absolute;
  top: 0;
  left: 0;
  background: #28304e;
  content: " ";
  -webkit-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
}
.themeWrapper .innerWrapper > div {
  -webkit-box-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
  padding-bottom: 20px;
}
.themeWrapper .innerWrapper .themesidebar.toggled {
  margin-left: -265px;
  box-shadow: 0px 5px 10px darkgrey;
}
.themeWrapper .innerWrapper .themesidebar {
  min-width: 265px;
  max-width: 265px;
  -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
    margin-right 0.4s ease-in-out, right 0.4s ease-in-out;
  transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
    margin-right 0.4s ease-in-out, right 0.4s ease-in-out;
  background: transparent;
  border-right: 0;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.09);
  /* box-shadow: 0px 5px 10px darkgrey; */
  z-index: 1;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}
.themeWrapper .innerWrapper .themesidebar-content {
  /* background: #fff; */
  -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
    margin-right 0.4s ease-in-out, right 0.4s ease-in-out;
  transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
    margin-right 0.4s ease-in-out, right 0.4s ease-in-out;
  height: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.themeWrapper .innerWrapper .themesidebar-content .divScrollMenu {
  overflow-y: auto;
}
.themesidebar-content {
  background: #fbfbfb;
}
.themeWrapper
  .innerWrapper
  .themesidebar-content
  .divScrollMenu::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border: none;
  background-color: #28304e; /* or add it to the track */
}
.themeWrapper
  .innerWrapper
  .themesidebar-content
  .divScrollMenu::-webkit-scrollbar-thumb {
  background: #28304e;
  /* border-radius: 4px; */
}

.themeWrapper .innerWrapper .themesidebar-brand {
  padding: 20px;
  background: #28304e;
  width: 100%;
  display: block;
  text-decoration: none;
  color: #fff;
}
.themeWrapper .innerWrapper .themesidebar-brand img {
  height: 44px;
}
.themeWrapper .innerWrapper .themesidebar-user {
  padding: 20px 10px 10px;
  /* background: #fff; */
  color: #203a45;
  text-align: center;
  /* background-image: url("../img/profile_bg.png"); */
  background-repeat: no-repeat;
  background-size: contain;

  /* background-size: cover;
  background-color: #28304e;
  position: relative;
  z-index: 1;
  color: #fff; */
}
.themeWrapper .innerWrapper .themesidebar-user h4 {
  margin: 0px;
  padding: 15px 0px 5px 0px;
}
.themeWrapper .innerWrapper .themesidebar-user h4 small {
  display: block;
  font-size: 14px;
  padding: 4px;
  font-weight: 400;
}
.themeWrapper .innerWrapper .themesidebar-user .theme-userProfile,
.themeProfileImg {
  margin: auto;
  position: relative;
  height: 75px;
  width: 75px;
  text-align: center;
  display: block;
  overflow: hidden;
  box-shadow: 0px 1px 5px -3px rgba(0, 0, 0, 0.75);
  border-radius: 50%;
}

/* .themesidebar-user::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(42deg, rgba(125,43,105,1) 30%, rgba(254,117,36,0.6) 60%, rgba(253,51,37,0.8) 100%); 
  background: linear-gradient(180deg,rgba(62,1,50,1) 40%, rgba(254,117,36,0.7) 70%, rgba(253,51,37,0.8)   100%);
  pointer-events: none;
} */

.themeWrapper .innerWrapper .themesidebar-user .theme-userProfile img,
.themeProfileImg img {
  top: 50%;
  left: 50%;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  /* height: 95px;
  width: 95px;
  max-height: 100%;
  max-width: 100%; */
}

.themeProfileImg {
  height: 115px;
  width: 115px;
  border-radius: 0px;
}
.themeWrapper .innerWrapper .themesidebar-nav {
  margin: 0px 10px;
}
.themeWrapper .innerWrapper .themesidebar-nav > li {
  padding: 8px;
}

.themesidebar .sidebarmenu-nav ul {
  padding: 0px;
}
.themesidebar .sidebarmenu-nav ul li {
  padding: 0px;
}
.themesidebar .sidebarmenu-nav .sidebarmenu-header {
  background: transparent;
  color: #adb5bd;
  padding: 5px 18px;
  font-size: 13px;
  font-weight: 600;
  text-transform: none;
}
.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item.main-item
  > .rc-collapse-header,
.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item.themesub-item
  > .rc-collapse-header {
  display: block;
  padding: 6px 20px 6px 10px;
  background: transparent;
  transition: background 0.1s ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  color: #1f0019;
  font-weight: 600;
  margin: 0px 8px;
  border-radius: 0.2rem;
  line-height: 1.5;
  font-size: 16px;
}
.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item.main-item
  > .rc-collapse-header:after,
.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item.themesub-item
  > .rc-collapse-header:after {
  content: "\F105";
  font-family: "FontAwesome";
  position: absolute;
  right: 17px;
  font-weight: 600;
  font-size: 17px;
  top: 50%;
  line-height: 1;
  margin-top: -8.5px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item.main-item.rc-collapse-item-active
  > .rc-collapse-header:after,
.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item.themesub-item.rc-collapse-item-active
  > .rc-collapse-header:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.themesidebar .sidebarmenu-nav .rc-collapse {
  border: 0px;
  background: transparent;
  border-radius: 0px;
}
.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item
  > .rc-collapse-header {
  outline: none;
  border: 0px;
}
.themesidebar .sidebarmenu-nav .rc-collapse > .rc-collapse-item {
  border: 0px;
}
.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item
  > .rc-collapse-header
  .arrow {
  display: none;
}

.themesidebar
  .sidebarmenu-nav
  .rc-collapse-content
  > .rc-collapse-content-box
  .themesub-link
  a
  span {
  /* border-radius: 5px;
  height: 20px;
  width: 20px; 
  font-size: 10px;*/

  border-radius: 10%;
  height: 25px;
  width: 25px;
  font-size: 10px;
  padding-top: 2px;

  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 2;
  margin: 2px 5px 0px 0px;
}
.themesidebar
  .sidebarmenu-nav
  .rc-collapse-content
  > .rc-collapse-content-box
  .themesub-link
  a {
  /* display: block; */
  /* margin-top: 5px; */
  padding: 3px 6px 3px 12px;
  font-size: 13px;
  /* display: -ms-flexbox;
  display: flex; 
  -ms-flex-align: start;
  align-items: flex-start;*/
  color: #1f0019;
  display: inline-flex;
  align-items: center;
}
.themesidebar
  .sidebarmenu-nav
  .rc-collapse-content
  > .rc-collapse-content-box
  .themesub-link
  a
  b {
  -ms-flex: 1;
  flex: 1;
  font-weight: 500;
}
.themesidebar
  .sidebarmenu-nav
  .rc-collapse-content
  > .rc-collapse-content-box
  .themesub-link
  a
  .subMenuNames {
  font-size: 13px;
}
.themesidebar .sidebarmenu-nav .rc-collapse-content > .rc-collapse-content-box {
  margin: 8px 0px 5px 0px;
}
.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item.themesub-item
  > .rc-collapse-header {
  padding: 5px 25px 5px 15px;
  font-size: 15px;
  color: #203a45;
  margin: 0px;
}
.themesidebar .sidebarmenu-nav .rc-collapse-content {
  padding: 0 8px;
  border-radius: 0px !important;
  -moz-transition: height 0.4s ease-in-out;
  -webkit-transition: height 0.4s ease-in-out;
  -o-transition: height 0.4s ease-in-out;
  transition: height 0.4s ease-in-out;
}
.themesidebar
  .sidebarmenu-nav
  .rc-collapse
  > .rc-collapse-item.main-item.rc-collapse-item-active
  > .rc-collapse-header {
  color: #212529;
  background: #e9ecef;
}

.themesub-link a:hover {
  color: #fb2925 !important;
}

/******************** InnerPage Header CSS *************************/
.innerWrapper .theme-header {
  /* width: 100%;
  margin-bottom: 25px; */
  display: flex;
  align-items: center;
  align-self: flex-start;
  color: #fff;
}
.innerWrapper .theme-header {
  /* .header-title { */
  margin: 0px;
  padding-left: 6px;
  font-size: 16px;
}
.innerWrapper .theme-header .header-title small {
  /* font-size: 13px;
  display: block;
  font-weight: 400; */
}
.innerWrapper .theme-header .clientThumIcon {
  margin: auto;
  position: relative;
  height: 36px;
  width: 36px;
  text-align: center;
  display: block;
  overflow: hidden;
  float: left;
  border-radius: 3px;
}
.innerWrapper .theme-header .clientThumIcon img {
  top: 50%;
  left: 50%;
  margin: auto;
  max-height: 90%;
  max-width: 100%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}
/* 
.innerWrapper .theme-header img {
  height: 35px;
  width: 35px;
  float: left;
  border-radius: 3px;
} */
.innerWrapper .navbar-theme {
  background: #28304e !important;
  box-shadow: none;
  padding: 14px 10px 14px 15px;
  height: 54px;
  -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
    margin-right 0.4s ease-in-out, right 0.4s ease-in-out;
  transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
    margin-right 0.4s ease-in-out, right 0.4s ease-in-out;

  position: fixed;
  top: 0;
  left: 265px;
  right: 0;
  z-index: 9999 !important;
}
.innerWrapper .navbar-theme.navToggledIn {
  left: 0px;
}
.innerWrapper .navbar-theme .sidebar-toggle {
  cursor: pointer;
  width: 26px;
  height: 26px;
  /* margin-left: 0.5rem; */
  display: -webkit-box !important;
  display: flex !important;
  justify-content: center;
  align-self: center;
}
.innerWrapper .navbar-theme .hamburger {
  position: relative;
  align-self: center !important;
}
.innerWrapper .navbar-theme .hamburger,
.innerWrapper .navbar-theme .hamburger:before,
.innerWrapper .navbar-theme .hamburger:after {
  cursor: pointer;
  height: 2px;
  width: 20px;
  background: rgba(255, 255, 255, 0.75);
  display: block;
  content: "";
  -webkit-transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
}
.innerWrapper .navbar-theme .hamburger:before {
  top: -6.5px;
  width: 20px;
  position: absolute;
}
.innerWrapper .navbar-theme .hamburger:after {
  bottom: -6.5px;
  width: 20px;
  position: absolute;
}
.innerWrapper .navbar-theme .nav-link {
  color: #fff !important;
  font-size: 16px;
  cursor: pointer;
  padding: 8px;
}
/* .innerWrapper .navbar-theme .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
} */
/* .innerWrapper .navbar-theme .nav-link:hover i.fa.fa-bell:after {
  background-color: rgba(255, 255, 255, 0.75);
} */
.innerWrapper .navbar-theme .nav-link.userSettings {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-self: flex-start;
  align-self: flex-start;
  color: #fff !important;
  padding: 6px 10px;
}
.innerWrapper .navbar-theme .nav-link.userSettings span {
  padding: 0px 5px;
  text-transform: uppercase;
  /* font-size: 13px; */
}
.innerWrapper .navbar-theme .nav-link i.fa.fa-bell:after {
  content: "";
  background-color: #ffffff;
  border-radius: 50%;
  display: block;
  height: 6px;
  width: 6px;
  position: absolute;
  top: 6px;
  right: 2px;
}

.custom-dropdown li a {
  display: block;
}
.custom-dropdown li:first-child {
  border-top: 0px !important;
}
.right-align {
  right: -10px !important;  
}
.innerWrapper .navbar-theme .dropdown-menu {
  border-radius: 3px;
  border: 0px;
  right: 0 !important;
  left: auto;
  position: absolute;
  z-index: 10;
  transition-timing-function: ease-in;
  transition: 0.2s;
  min-width: 138px;
  background: #fff;
  padding: 8px;
  margin-top: 0px;
  -webkit-transform: scale(0.8) !important;
  transform: scale(0.8) !important;
  -webkit-transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
  transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);
  opacity: 0;
  visibility: hidden;
  display: block;
  -webkit-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
}
.innerWrapper .navbar-theme .dropdown-menu:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #fafafa;
  position: absolute;
  top: -7px;
  right: 5px;
}
.innerWrapper .navbar-theme .dropdown-menu ul {
  padding: 0px;
  margin: 0px;
}
.innerWrapper .navbar-theme .dropdown-menu.theme-notification.dropShow,
.innerWrapper .navbar-theme .dropdown-menu.custom-dropdown.dropShow {
  /* transition: 0.25s;
  transition-timing-function: ease-out;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  display: block; */
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transform-origin: 29px -50px;
  transform-origin: 29px -50px;
  opacity: 1;
  visibility: visible;
}

.innerWrapper .navbar-theme .theme-notification {
  min-width: 280px;
  padding: 0px;
}
.innerWrapper .navbar-theme .theme-notification .dropdown-content a {
  padding: 15px 8px 15px;
  display: block;
  overflow: hidden;
  color: #444;
}
.innerWrapper .navbar-theme .theme-notification .notificationimage {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background-color: #e4e4e4;
  float: left;
  text-align: center;
  overflow: hidden;
}
.innerWrapper .navbar-theme .theme-notification .notificationimage img {
  height: 38px;
  width: 38px;
  color: #979595;
  line-height: 36px;
}
.innerWrapper .navbar-theme .theme-notification .notificationBlock {
  padding-left: 50px;
  line-height: 14px;
}
.innerWrapper .navbar-theme .theme-notification .notificationBlock p {
  margin: 0px;
}
.innerWrapper .navbar-theme .theme-notification .notificationBlock .user-name {
  color: #4285f4;
  font-weight: 500;
}
.innerWrapper .navbar-theme .theme-notification .notificationBlock small {
  display: block;
  font-size: 12px;
}

.innerWrapper .navbar-theme .theme-notification .dropdown-header span.badge {
  margin-left: 5px;
  line-height: 1.2;
}
.innerWrapper .navbar-theme .theme-notification .dropdown-header,
.innerWrapper .navbar-theme .theme-notification .dropdown-footer {
  text-align: center;
  font-size: 13px;
  background: #fafafa;
  color: #444;
  padding: 8px;
  border-radius: 3px 3px 0px 0px;
}
.innerWrapper .navbar-theme .theme-notification .dropdown-footer {
  border-radius: 0px 0px 3px 3px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.innerWrapper .navbar-theme .theme-notification .dropdown-content {
  height: 262px;
  overflow-y: auto;
}
.innerWrapper .navbar-theme .theme-notification .dropdown-content ul li,
.custom-dropdown li {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.innerWrapper .navbar-theme .headerAcadDropdown {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.innerWrapper .navbar-theme .nav-link.acadeYearSettings {
  /* font-size: 13px; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-self: flex-start;
  align-self: flex-start;
  color: #fff !important;
  padding: 7px 10px 6px 0px;
  margin-left: 10px;
}
.innerWrapper .navbar-theme .nav-link.acadeYearSettings i {
  font-size: 16px;
  margin-left: 5px;
  line-height: 18px;
}

.themeWrapper > .be-content {
  margin-left: 0;
}
/* .innerWrapper .navbar-theme .nav-link.acadeYearSettings:after {
  content: "\F107";
  font-family: "FontAwesome";
  line-height: 1;
  font-size: 18px;
  padding-left: 5px;
} */
/* .innerWrapper .navbar-theme .headerAcadDropdown .Select-control {
  width: 100px;
  border: 0px;
  background: transparent !important;
  line-height: 1.5;
  height: 34px;
  font-size: 15px;
}
.innerWrapper
  .navbar-theme
  .headerAcadDropdown
  .Select.has-value.Select--single
  > .Select-control
  .Select-value
  .Select-value-label,
.innerWrapper
  .navbar-theme
  .headerAcadDropdown
  .Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  color: #fff !important;
} */

.innerWrapper .navbar-theme .headerAcadDropdown .dropdown-menu button {
  margin-top: 5px;
}
.innerWrapper .navbar-theme .headerAcadDropdown .Select-control {
  box-shadow: none;
  border-color: #efeeee;
  border-radius: 3px;
  font-size: 15px;
}
.innerWrapper .navbar-theme .dropdown-menu.profile-dropdown {
  padding: 4px 0px 2px;
  width: 170px;
  right: inherit;
}
.innerWrapper .navbar-theme .dropdown-menu.profile-dropdown li a {
  color: #444;
  border: 0px;
  border-radius: 0px;
  padding: 9px 8px;
  cursor: pointer;
  font-size: 15px;
}
.innerWrapper .navbar-theme .dropdown-menu.profile-dropdown li a i {
  padding: 0px 4px;
}
.innerWrapper .navbar-theme .dropdown-menu.profile-dropdown li a:hover,
.innerWrapper .navbar-theme .dropdown-menu.profile-dropdown li a:focus {
  background: #f8f9fa;
}
/******************** InnerPage Main CSS *************************/
.innerWrapper .be-content {
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
    margin-right 0.4s ease-in-out, right 0.4s ease-in-out;
  transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
    margin-right 0.4s ease-in-out, right 0.4s ease-in-out;
  position: inherit;
  left: inherit;
  margin-left: 265px;
  padding: 64px 20px 0px 20px;
}
.innerWrapper .be-content.toggledIn {
  margin-left: 0px;
}
.innerWrapper .be-content .page-head-title,
.innerWrapper .be-content .page-head-title label {
  color: #fff;
}
.innerWrapper .be-content .page-head-title.tab-subtitle,
.innerWrapper .be-content .page-head-title label.tab-subtitle {
  color: #444;
}
/******************** Inner DashboardPage CSS *************************/
.theme-Dashboard {
  margin-top: 60px;
}
.theme-Dashboard .card {
  -webkit-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
}
.theme-Dashboard .card .card-body h5 {
  font-weight: 500;
}
.theme-Dashboard .card .card-body h3 {
  margin: 20px 0px 0px;
  line-height: 1.2;
  font-weight: 600;
}
.theme-Dashboard .card .card-body h3 i {
  font-size: 20px;
  margin-right: 5px;
}

.theme-Dashboard .statistic_card {
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  border: 0px;
  -webkit-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  padding: 10px;
  margin-top: 10px;
}

.theme-dashboard-total {
  margin-top: 10px;
}
.theme-dashboard-total .card .card-body h3 {
  margin: 0px 0px 2px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

/******************** Spotlight Search CSS *************************/
.mainSpotlightSearch {
  padding-top: 60px;
}
.innerSpotlightSearch {
  padding: 0px;
  border-radius: 4px;
  border: 0px;
  box-shadow: none;
  max-width: 650px;
  -webkit-transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
  -moz-transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
  -o-transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
  transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
}
.innerSpotlightSearch .react-autosuggest__input {
  -webkit-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 6px -4px rgba(0, 0, 0, 0.75);
  background: transparent;
  border-radius: 4px;
  border: 0px;
  height: 48px !important;
  padding: 12px 20px 12px 45px !important;
  line-height: 1.5;
  font-size: 20px;
  width: 100%;
}
.innerSpotlightSearch .react-autosuggest__container {
  position: relative;
  -webkit-transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
  -moz-transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
  -o-transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
  transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
}
.innerSpotlightSearch .react-autosuggest__container:before {
  font-family: "FontAwesome";
  position: absolute;
  top: 9px;
  left: 14px;
  content: "\F002";
  font-size: 20px;
  color: #6d6d6d;
}
.innerSpotlightSearch
  .react-autosuggest__container--open
  .react-autosuggest__suggestions-container {
  border-radius: 4px;
  border: 0px;
  -webkit-transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
  -moz-transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
  -o-transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
  transition: all 0.2s ease-in-out, width 0, height 0, top 0, left 0;
}
.innerSpotlightSearch .react-autosuggest__suggestion {
  padding: 1px 12px;
}
.innerSpotlightSearch .suggestion-content span.name {
  margin: 0px;
  line-height: 2;
}
.innerSpotlightSearch
  .react-autosuggest__container--open
  .react-autosuggest__suggestions-container {
  border: 0px;
  display: block;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  max-height: 500px;
}
.researchTable thead {
  float: left;
  width: 60%;
}
.researchTable tbody {
  float: left;
  width: 40%;
}
.researchTable thead tr th {
  font-size: 14px;
}
.researchTable thead tr,
.researchTable tbody tr {
  display: inline-table;
  width: 100%;
  min-height: 54px;
}
.configrationTable .table-responsive::-webkit-scrollbar {
  height: 6px;
  background-color: #a8a8a8;
  border-radius: 3px;
}
.configrationTable .table-responsive {
  margin-top: 10px;
}
.configTableSeqNo {
  display: inline-block;
  vertical-align: top;
  font-size: 17px;
  font-weight: bold;
}
.configTableTitle {
  display: inline-block;
  font-size: 17px;
}
.configTableTitle p {
  margin-bottom: 4px;
}
.configrationTable .table tr td {
  min-width: 195px;
}
.configrationTable .table tr td:first-child {
  min-width: 55px;
}

.configrationTable .table td .documentBrowse {
  min-width: 200px;
}
.configrationTable .table thead tr th:last-child {
  text-align: center;
}
.configrationTable .table thead tr,
.configrationTable .table tbody tr {
  position: relative;
}
.configrationTable .table tr td:last-child,
.configrationTable .table thead tr th:last-child {
  position: sticky;
  right: 0;
  width: 130px;
  min-width: 130px;
  text-align: center;
  background: #e9ecef;
  top: 0;
  bottom: 0;
}

/******************** Responsive Design MediaQuery CSS *************************/

/* Desktops and laptops -----------  */

@media (max-width: 1380px) {
  .themeWrapper .innerWrapper .themesidebar {
    min-width: 250px;
    max-width: 250px;
  }
  .innerWrapper .be-content {
    margin-left: 250px;
  }
  .innerWrapper .navbar-theme {
    left: 250px;
  }
  .themeWrapper .innerWrapper .themesidebar.toggled {
    margin-left: -250px;
  }
  .innerWrapper .navbar-theme {
    /* left: 150px; */
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .innerWrapper .be-content {
    margin-left: 0;
  }
  .themeWrapper .innerWrapper .themesidebar {
    left: -250px;
  }
  .innerWrapper .navbar-theme {
    left: 0;
  }
  .themeWrapper .innerWrapper .themesidebar.toggled {
    margin-left: inherit;
    left: 0;
  }
  .innerWrapper .navbar-theme.navToggledIn {
    left: 250px;
  }
  .innerSpotlightSearch .react-autosuggest__input {
    font-size: 18px;
  }
  .innerSpotlightSearch {
    max-width: 500px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .innerWrapper .be-content {
    margin-left: 0;
  }
  .themeWrapper .innerWrapper .themesidebar {
    left: -250px;
  }
  .innerWrapper .navbar-theme {
    left: 0;
  }
  .themeWrapper .innerWrapper .themesidebar.toggled {
    margin-left: inherit;
    left: 0;
  }
  .innerWrapper .navbar-theme.navToggledIn {
    left: 250px;
  }
  .innerSpotlightSearch .react-autosuggest__input {
    font-size: 18px;
  }
  .innerSpotlightSearch {
    max-width: 500px;
  }
}

@media (max-width: 1024px) {
  .theme-Dashboard .card .card-body h3 {
    font-size: 18px;
    margin-top: 8px !important;
  }
  .theme-Dashboard .card .card-body h3 i {
    font-size: 18px;
  }
  .innerWrapper .navbar-theme .dropdown-menu.profile-dropdown {
    width: 140px;
  }
  .innerWrapper .navbar-theme .dropdown-menu.profile-dropdown li a {
    padding: 6px 8px;
  }
  .themeWrapper .innerWrapper .themesidebar {
    left: -250px;
  }
  .themeWrapper .innerWrapper .themesidebar.toggled {
    left: 0;
    margin-left: inherit;
  }
  .innerWrapper .navbar-theme.navToggledIn {
    left: 135px;
    padding-left: 5px;
    /* -webkit-transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
      margin-right 0.4s ease-in-out, right 0.4s ease-in-out;
    transition: margin-left 0.4s ease-in-out, left 0.4s ease-in-out,
      margin-right 0.4s ease-in-out, right 0.4s ease-in-out; */
  }
  .innerWrapper .be-content {
    margin-left: 0px;
  }
  .innerWrapper .navbar-theme {
    left: 0;
  }
  .innerWrapper .navbar-theme .nav-link.userSettings span,
  .innerWrapper .navbar-theme .nav-link.userSettings i {
    display: none;
  }
  .themeWrapper .innerWrapper .themesidebar-brand img {
    height: 40px;
  }
  .innerWrapper .theme-header .header-title {
    font-size: 15px;
  }
  .innerWrapper .navbar-theme .nav-link {
    font-size: 14px;
  }
  .innerWrapper .theme-header .header-title small,
  .innerWrapper .theme-header .header-title small,
  .innerWrapper .navbar-theme .dropdown-menu.profile-dropdown li a,
  .innerWrapper .navbar-theme .headerAcadDropdown .Select-control {
    font-size: 13px;
  }
  .innerWrapper .navbar-theme {
    padding: 12px 10px 11px 15px;
    min-height: 60px;
  }
  .innerWrapper .navbar-theme .dropdown-menu {
    min-width: 135px !important;
  }
}

@media (max-width: 991px) {
  .innerSpotlightSearch {
    max-width: 500px;
  }
  .innerWrapper .be-content {
    margin-left: 0px;
    padding: 54px 15px 0px 15px;
  }
  .innerWrapper .navbar-theme {
    left: 0;
    padding: 10px;
    height: 54px;
  }
  .innerWrapper .theme-header img {
    height: 30px;
    width: 30px;
  }
  .innerWrapper .navbar-theme.navToggledIn {
    /* left: 120px; */
    left: 250px;
  }
  .innerWrapper .theme-header .header-title {
    font-size: 14px;
  }
  .innerWrapper .navbar-theme .nav-link {
    font-size: 13px;
  }
  .innerWrapper .theme-header .header-title small {
    font-size: 12px;
  }
  .themeWrapper .innerWrapper .themesidebar-brand img {
    height: 34px;
  }
  .themeWrapper .innerWrapper .themesidebar-brand {
    padding-right: 135px;
    padding: 13px 10px;
  }
  /* @page{size: landscape;margin: 2cm;} */
}

.show {
  visibility: visible !important;
}

.dropdown-menu, .right-align {
  top: 40px !important;
  right: -10px !important;
}

